/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';

import config from 'config';
import { enqueueNotification, hideModal } from 'stores';
import * as utils from 'utils';
import { logoutAuth0 } from 'utils/auth0/auth0';

// app
import { adminAxios, authAxios } from './admin.api';
import { USER_DETAILS } from './queryKeys';

export const useCreateUserProgrammes = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const handleSettled = () => {
    dispatch(hideModal());
  };

  const handleError = (err) => {
    const errorParams = {
      file: 'admin.mutations.createUserProgrammes',
      message: 'API fetch error (admin.put)',
    };
    utils.api.handleError(err, errorParams);
    utils.api.handleUnauthorized(err, null, logoutAuth0);
    dispatch(enqueueNotification('notification.admin.userPostFail', 'error'));
  };

  const handleSuccess = (res) => {
    const { data } = res || {};
    queryClient.invalidateQueries([USER_DETAILS, data?.data?.id]);
    dispatch(enqueueNotification('notification.admin.userPostSuccess', 'success'));
  };

  return useMutation(
    async (userId) => {
      if (!userId) {
        throw new Error('Invalid user id');
      }

      const data = { id: userId };
      const res = await adminAxios.put(`user/programmes/${userId}`, data);
      return res;
    },
    {
      onSuccess: (res) => handleSuccess(res),
      onError: (err) => handleError(err),
      onSettled: (data) => handleSettled(data),
    }
  );
};

export const useCreateBinderManagement = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const handleError = (err) => {
    const errorParams = {
      file: 'admin.mutations.useCreateBinderManagement',
      message: 'API fetch error (admin.put)',
    };
    utils.api.handleError(err, errorParams);
    utils.api.handleUnauthorized(err, null, logoutAuth0);
    dispatch(enqueueNotification('notification.admin.userPostBMFail', 'error'));
  };

  const handleSuccess = (data) => {
    const { userId } = data;

    queryClient.invalidateQueries([USER_DETAILS, userId]);
    dispatch(enqueueNotification('notification.admin.userPostBMSuccess', 'success'));
  };

  return useMutation(
    async (userId) => {
      if (!userId) {
        throw new Error('Invalid user id');
      }

      const res = await adminAxios.post(`user/binderManagement?userId=${userId}`, { userId });
      return res;
    },
    {
      onSuccess: (res) => handleSuccess(res),
      onError: (err) => handleError(err),
    }
  );
};

export const useCreateUser = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const history = useHistory();

  const rolesKeys = utils.user.getRolesString().map((role) => role.value);

  const handleSettled = () => {
    dispatch(hideModal());
  };

  const handleError = (err) => {
    const errorParams = {
      file: 'admin.mutations.createUser',
      message: 'API fetch error (admin.post)',
    };
    utils.api.handleError(err, errorParams);
    utils.api.handleUnauthorized(err, null, logoutAuth0);
    dispatch(enqueueNotification('notification.admin.userPostFail', 'error'));
  };

  const handleSuccess = (res) => {
    const { data } = res || {};

    queryClient.invalidateQueries(['userList']);
    dispatch(enqueueNotification('notification.admin.userPostSuccess', 'success'));

    history.push(`${config.routes.admin.userDetails}/${data?.data?.id}`);
  };

  return useMutation(
    async (newUser) => {
      const data = {
        ...newUser,
        departments: [],
        clientOfficeIds: [],
        marketIds: [],
        sourceSystemIds: [],
      };

      if (!utils.generic.isValidObject(newUser) || !rolesKeys.includes(newUser.role)) {
        if (!rolesKeys.includes(newUser.role)) throw new Error('Invalid role type');

        throw new Error('Invalid user request body');
      }

      const result = await adminAxios.post('user', data);
      return result;
    },
    {
      onSuccess: (res) => handleSuccess(res),
      onError: (err) => handleError(err),
      onSettled: (data) => handleSettled(data),
    }
  );
};

export const useEditUser = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const rolesKeys = utils.user.getRolesString().map((role) => role?.value);
  const handleError = (err) => {
    const errorParams = {
      file: 'admin.mutations.editUser',
      message: 'Data missing for PATCH request',
    };

    utils.api.handleError(err, errorParams);
    utils.api.handleUnauthorized(err, null, logoutAuth0);
    dispatch(enqueueNotification('notification.admin.userPatchFail', 'error'));
  };

  const handleSuccess = (res) => {
    const { data } = res || {};
    queryClient.invalidateQueries(USER_DETAILS, data?.data?.id);
    queryClient.invalidateQueries(['userList']);
    dispatch(enqueueNotification('notification.admin.userPatchSuccess', 'success'));
  };

  return useMutation(
    async (requestData) => {
      const { updatedUser: editedUser, user: previousUser } = requestData;

      if (!utils.generic.isValidObject(editedUser) || !rolesKeys.includes(editedUser.role) || !utils.generic.isValidObject(previousUser)) {
        if (!rolesKeys.includes(editedUser?.role)) throw new Error('Invalid role type');
        throw new Error('Invalid user details');
      }

      const {
        departments,
        subDepartments,
        defaultDepartmentId,
        offices,
        carriers,
        clients,
        clientId,
        products,
        coverholder,
        operationalMarkets,
        reportReadAll,
        ...rest
      } = editedUser;

      const isProducer = utils.user.isProducer(editedUser);
      const isUnderwriter = utils.user.isUnderwriter(editedUser);
      const isBroker = utils.user.isBroker(editedUser);

      const editedUserToIds = {
        ...rest,
        defaultDepartmentId: defaultDepartmentId?.id || null,
        ...(subDepartments && {
          departments: !isUnderwriter
            ? departments.map((department) => ({ id: department.id, sourceSystemId: department.sourceSystemId }))
            : [],
          subDepartments: !isUnderwriter ? subDepartments.map((department) => department.id) : [],
        }),

        ...(offices && { clientOfficeIds: isProducer ? offices.map((office) => office.id) : [] }),
        ...(operationalMarkets && { marketIds: isUnderwriter ? [operationalMarkets?.id] : [] }),
        reportReadAll: isBroker ? reportReadAll : false,
      };

      const previousUserToIds = {
        ...previousUser,
        clientOfficeIds: previousUser.offices.map((office) => office.id),
      };

      const patchChanges = utils.generic.getDifferences(previousUserToIds, editedUserToIds);
      const result = await adminAxios.patch(`user/${previousUser.id}`, patchChanges);
      return result?.data?.data;
    },
    {
      onSuccess: (res) => handleSuccess(res),
      onError: (err) => handleError(err),
    }
  );
};

export const useEditUserProgrammes = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const handleError = (err) => {
    const errorParams = {
      file: 'admin.mutations.patchUserProgrammes',
      message: 'API PATCH error',
    };

    utils.api.handleError(err, errorParams);
    utils.api.handleUnauthorized(err, null, logoutAuth0);
    dispatch(enqueueNotification(utils.api.getErrorMessage(err), 'error'));
  };

  const handleSuccess = (res) => {
    const { data } = res;
    queryClient.setQueryData(['userProgrammes', data?.id], data);

    dispatch(enqueueNotification('notification.patchUserProgrammes.success', 'success'));
  };

  return useMutation(
    async (requestData) => {
      const { programmesUserId, programmesData } = requestData;
      if (!programmesData || isEmpty(programmesData) || !programmesUserId) {
        throw new Error('Invalid user programme data');
      }

      const res = await authAxios.patch(`/v1/users/edge/${programmesUserId}`, programmesData);
      return res;
    },
    {
      onSuccess: (res) => handleSuccess(res),
      onError: (err) => handleError(err),
    }
  );
};

export const useSendVerificationEmail = () => {
  const dispatch = useDispatch();

  const handleError = (err) => {
    const errorParams = {
      file: 'admin.mutations.sendVerificationEmail',
      message: 'API PATCH error',
    };

    utils.api.handleError(err, errorParams);
    utils.api.handleUnauthorized(err, null, logoutAuth0);
    dispatch(enqueueNotification('notification.sendEmailVerification.fail', 'error'));
  };

  const handleSuccess = () => {
    dispatch(enqueueNotification('notification.sendEmailVerification.success', 'success'));
  };

  return useMutation(
    async (userId) => {
      if (!userId) {
        throw new Error('Invalid user id');
      }
      const data = { id: userId };
      const res = await adminAxios.put(`/user/${userId}/verify`, data);
      return res;
    },
    {
      onSuccess: (res) => handleSuccess(res),
      onError: (err) => handleError(err),
    }
  );
};

export const useGetClientProgrammes = () => {
  const handleError = (err) => {
    const errorParams = {
      file: 'admin.queries.getClientProgrammes',
    };

    utils.api.handleError(err, errorParams);
  };

  return useMutation(
    async (clientOfficeIds) => {
      if (utils.generic.isInvalidOrEmptyArray(clientOfficeIds)) return [];
      const officeParams = clientOfficeIds?.join(',');
      const result = await authAxios.get(`/v1/clients/offices?ids=${officeParams}`);
      const data = orderBy(result?.data, ['name'], ['asc']);
      return data;
    },
    {
      onError: (err) => handleError(err),
      keepPreviousData: true,
      enabled: false,
    }
  );
};

export const useSetEnabled = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const handleError = (err) => {
    const errorParams = {
      file: 'admin.mutations.setEnabled',
      message: 'API PUT error',
    };
    utils.api.handleError(err, errorParams);
    utils.api.handleUnauthorized(err, null, logoutAuth0);
    dispatch(enqueueNotification('notification.admin.userSetAbilityFail', 'error'));
  };

  const handleSuccess = () => {
    queryClient.invalidateQueries([USER_DETAILS]);
    queryClient.invalidateQueries(['userList']);
    dispatch(enqueueNotification('notification.admin.userSetEnabledSuccess', 'success'));
  };

  return useMutation(
    async (userId) => {
      if (!userId) {
        throw new Error('Invalid user id');
      }

      const res = await adminAxios.put(`user/${userId}/enable`);
      return res;
    },
    {
      onSuccess: (res) => handleSuccess(res),
      onError: (err) => handleError(err),
    }
  );
};

export const useSetDisabled = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const handleError = (err) => {
    const errorParams = {
      file: 'admin.mutations.setEnabled',
      message: 'API PUT error',
    };
    utils.api.handleError(err, errorParams);
    utils.api.handleUnauthorized(err, null, logoutAuth0);
    dispatch(enqueueNotification('notification.admin.userSetAbilityFail', 'error'));
  };

  const handleSuccess = () => {
    queryClient.invalidateQueries([USER_DETAILS]);
    queryClient.invalidateQueries(['userList']);
    dispatch(enqueueNotification('notification.admin.userSetDisabledSuccess', 'success'));
  };

  return useMutation(
    async ({ userId }) => {
      if (!userId) {
        throw new Error('Invalid user id');
      }

      const res = await adminAxios.put(`user/${userId}/disable`);
      return res;
    },
    {
      onSuccess: (res) => handleSuccess(res),
      onError: (err) => handleError(err),
    }
  );
};

export const useAddToReporting = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const handleError = (err) => {
    const errorParams = {
      file: 'admin.mutations.addToReporting',
      message: 'API POST error',
    };

    utils.api.handleError(err, errorParams);
    utils.api.handleUnauthorized(err, null, logoutAuth0);
    dispatch(enqueueNotification('notification.admin.userAddToReportingFail', 'error'));
  };
  const handleSuccess = () => {
    queryClient.invalidateQueries([USER_DETAILS]);
    queryClient.invalidateQueries(['userList']);
    dispatch(enqueueNotification('notification.admin.userAddToReportingSuccess', 'success'));
  };

  return useMutation(
    async (userId) => {
      if (!userId) {
        throw new Error('Invalid user id');
      }
      const res = await adminAxios.post(`/user/reporting?userId=${userId}`);
      return res;
    },
    {
      onSuccess: (res) => handleSuccess(res),
      onError: (err) => handleError(err),
    }
  );
};

export const useOverrideUser = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const handleError = (err) => {
    const errorParams = {
      file: 'admin.mutations.overrideUser',
      message: 'API POST error',
    };

    utils.api.handleError(err, errorParams);
    utils.api.handleUnauthorized(err, null, logoutAuth0);
    dispatch(enqueueNotification('notification.admin.userOverrideFail', 'error'));
  };

  const handleSuccess = () => {
    queryClient.invalidateQueries([USER_DETAILS]);

    dispatch(enqueueNotification('notification.admin.userOverrideSuccess', 'success'));
  };

  return useMutation(
    async (data) => {
      const { userIdFrom, userIdTo } = data;

      if (!userIdFrom || !userIdTo) {
        throw new Error('Invalid user IDs');
      }

      await adminAxios.put(`/user/${userIdFrom}/${userIdTo}`);

      return userIdTo;
    },
    {
      onSuccess: () => handleSuccess(),
      onError: (err) => handleError(err),
    }
  );
};
