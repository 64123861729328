import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';

// mui
import { makeStyles, Typography } from '@material-ui/core';

import { Button, FormActions, FormContainer, FormFields, FormFileDrop, FormGrid, FormText } from 'components';

import * as utils from 'utils';

import { ParentSelect } from './ParentSelect';

// app
import styles from './AddEditParentClient.styles';

export const AddEditParentClientView = ({ fields, actions, logo, allOptions, loading, handleLoadMore, onSearch }) => {
  const classes = makeStyles(styles, { name: 'AddEditParentClient' })();

  const defaultValues = utils.form.getInitialValues(fields);
  const validationSchema = utils.form.getValidationSchema(fields);

  const { control, reset, handleSubmit, formState, setValue, watch } = useForm({
    defaultValues,
    ...(validationSchema && { resolver: yupResolver(validationSchema) }),
  });

  const { errors } = formState;
  const cancel = actions && actions.find((action) => action.name === 'cancel');
  const submit = actions && actions.find((action) => action.name === 'submit');

  const handleUpdateFile = (file) => {
    setValue('logo.0', file);
  };

  const onCancel = () => {
    cancel && utils.generic.isFunction(cancel.handler) && cancel.handler();
    reset();
  };

  const onSubmit = (data) => submit && utils.generic.isFunction(submit.handler) && submit.handler(data);

  return (
    <div className={classes.root}>
      <FormContainer type="dialog" onSubmit={handleSubmit(onSubmit)} data-testid="form-addParentClient">
        <FormFields type="dialog">
          <FormGrid container>
            <FormGrid item xs={12}>
              <FormText {...utils.form.getFieldProps(fields, 'name')} control={control} error={errors.name} />
            </FormGrid>
            <FormGrid item xs={12}>
              <ParentSelect
                allOptions={allOptions}
                value={watch('odsParentClientName')}
                onChange={(newValue) => {
                  setValue('odsParentClientName', newValue);
                }}
                onLoadMore={handleLoadMore}
                loading={loading}
                label={utils.string.t('admin.form.odsParentClientName.label')}
                onSearch={onSearch}
              />
            </FormGrid>
            {logo ? (
              <FormGrid item xs={12}>
                <div className={classes.logo}>
                  <img src={logo} alt="logo" />
                </div>
              </FormGrid>
            ) : null}
            <FormGrid item xs={12}>
              <LogoInstructions />
              <FormFileDrop
                control={control}
                onChange={(file) => {
                  setValue('logo', file);
                }}
                {...utils.form.getFieldProps(fields, 'logo')}
                dragLabel={utils.string.t('admin.uploadLogoLabel')}
                showUploadPreview={false}
                showEditImage
                handleUpdateFile={handleUpdateFile}
                fileNameLength={30}
                onlyOneFile
              />
            </FormGrid>
          </FormGrid>
        </FormFields>

        <FormActions type="dialog">
          {cancel && <Button text={cancel.label} variant="text" onClick={onCancel} disabled={formState.isSubmitting} />}
          {submit && <Button text={submit.label} type="submit" disabled={formState.isSubmitting} color="primary" />}
        </FormActions>
      </FormContainer>
    </div>
  );
};

export const LogoInstructions = () => {
  const classes = makeStyles(styles, { name: 'AddEditParentClient' })();

  return (
    <>
      <Typography variant="body2" className={classes.instructionsLabel}>
        {utils.string.t('admin.logoInstructions')}
      </Typography>
      <ol className={classes.instructions}>
        <li>
          <Typography variant="body2" className={classes.logoInstructionsText}>
            {utils.string.t('admin.logoInstructions1')}
          </Typography>
        </li>
        <li>
          <Typography variant="body2" className={classes.logoInstructionsText}>
            {utils.string.t('admin.logoInstructions2')}
          </Typography>
        </li>
      </ol>
    </>
  );
};

AddEditParentClientView.propTypes = {
  fields: PropTypes.array,
  actions: PropTypes.array,
  logo: PropTypes.string || null,
};
