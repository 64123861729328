import { Box, Typography } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import { useSelector } from 'react-redux';

import { Button, FormGrid, Restricted } from 'components';
import { useQuoteBindStyles } from 'modules/QuoteBind';

import { useConfigContext } from 'config';
import { ROLE_BROKER, ROLE_PRODUCER } from 'consts';
import { ENABLED_ENDORSEMENT_PRODUCTS_PROD } from 'consts/quote-bind';
import * as utils from 'utils';

import { EndorsementQuotes, Endorsements, NewEndorsement } from './Endorsement';
import { PolicyCard, PolicyCardSkeleton } from './PolicyCard';

export const RiskPolicyView = ({
  isLoading,
  riskIsLoading,
  policy,
  isCanceled,
  isExtended,
  policyCurrency,
  hasBoundQuote,
  parties,
  effectiveEndorsements,
  pendingEndorsements,
  quotedEndorsements,
  latestEffectiveEndorsementDate,
  hasBackdateCancellation,
  hasBackdateEndorsement,
  hasEndorsementAdditionalPremium,
  hasRestrictedEndorsementForProducer,
  hasSurplusLineTax,
  handleAddEndorsement,
  handleReQuoteEndorsement,
  handlePreBind,
}) => {
  const classes = useQuoteBindStyles();
  const config = useConfigContext();
  const showPreBind = policy?.facility?.preBind || false;
  const user = useSelector((state) => state.user);
  const isProducer = utils.user.isProducer(user);

  const isEnabled = () => {
    if (isProducer && hasRestrictedEndorsementForProducer) {
      return false;
    }

    if (config.features?.ENABLE_ENDORSEMENT === true) {
      return true;
    }

    return ENABLED_ENDORSEMENT_PRODUCTS_PROD.includes(policy?.riskType);
  };

  return (
    <>
      <Box className={classes.root} data-testid="RiskPolicy">
        <Box className={classes.header} mb={2} display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center" justifyContent="start">
            <Typography variant="h3" className={classes.title}>
              {utils.string.t('app.policy')}
            </Typography>
          </Box>
          <Restricted include={[ROLE_BROKER, ROLE_PRODUCER]}>
            <Box display="flex" alignItems="center" justifyContent="end">
              {hasBoundQuote ? (
                <>
                  {showPreBind ? (
                    <Button
                      icon={DescriptionIcon}
                      size="xsmall"
                      variant="outlined"
                      color="primary"
                      text={utils.string.t('app.preBindForm')}
                      onClick={handlePreBind(policy, false)}
                      style={{ marginRight: 10 }}
                    />
                  ) : null}
                  {isEnabled() ? (
                    <NewEndorsement
                      handleAddEndorsement={handleAddEndorsement}
                      policy={policy}
                      hasBackdateEndorsement={hasBackdateEndorsement}
                      latestEffectiveEndorsementDate={latestEffectiveEndorsementDate}
                    />
                  ) : null}
                </>
              ) : null}
            </Box>
          </Restricted>
        </Box>
        <Box className={classes.content}>
          <FormGrid container spacing={2}>
            {isLoading || riskIsLoading || !policy ? (
              <FormGrid item xs={12} sm={6} md={6} lg={4}>
                <PolicyCardSkeleton />
              </FormGrid>
            ) : (
              <>
                <FormGrid item xs={12} sm={6} md={6} lg={4}>
                  <PolicyCard
                    policy={policy}
                    hasBoundQuote={hasBoundQuote}
                    parties={parties}
                    handlePreBind={handlePreBind}
                    endorsements={effectiveEndorsements}
                    hasAnyEndorsements={effectiveEndorsements?.length > 0 || pendingEndorsements?.length || quotedEndorsements?.length}
                    isCanceled={isCanceled}
                    isExtended={isExtended}
                    hasSurplusLineTax={hasSurplusLineTax}
                    hasBackdateCancellation={hasBackdateCancellation}
                  />
                </FormGrid>
                <FormGrid item xs={12} sm={6} md={6} lg={4}>
                  {effectiveEndorsements?.length > 0 ? (
                    <Endorsements
                      title={utils.string.t('app.activeEndorsement_plural')}
                      endorsements={effectiveEndorsements}
                      policyCurrency={policyCurrency}
                      riskType={policy?.riskType}
                      displayIndex
                      testid="effectiveEndorsements"
                    />
                  ) : null}
                </FormGrid>
                <FormGrid item xs={12} sm={6} md={6} lg={4}>
                  {pendingEndorsements?.length > 0 ? (
                    <Endorsements
                      title={utils.string.t('app.boundEndorsement_plural')}
                      canCancel={false}
                      endorsements={pendingEndorsements}
                      policyCurrency={policyCurrency}
                      riskType={policy?.riskType}
                      testid="pendingEndorsements"
                    />
                  ) : null}
                </FormGrid>
              </>
            )}
          </FormGrid>
        </Box>
      </Box>
      {quotedEndorsements?.length > 0 ? (
        <EndorsementQuotes
          title={utils.string.t('app.endorsements_quote_plural')}
          endorsements={quotedEndorsements}
          policyCurrency={policyCurrency}
          policy={policy}
          isEndorsementEnabled={isEnabled()}
          hasBackdateEndorsement={hasBackdateEndorsement}
          latestEffectiveEndorsementDate={latestEffectiveEndorsementDate}
          hasEndorsementAdditionalPremium={hasEndorsementAdditionalPremium}
          handleReQuoteEndorsement={handleReQuoteEndorsement}
        />
      ) : null}
    </>
  );
};
