import { useState, useEffect } from 'react';

// app

import { Button, FormCheckbox, FormGrid } from 'components';

// mui
import { makeStyles, Box, Popover, Checkbox, FormControlLabel } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';

import * as utils from 'utils';
import { is } from 'date-fns/locale';

const useStyles = makeStyles((theme) => ({
  bindBox: {
    padding: theme.spacing(2),
    maxWidth: '300px',
  },
  dateLabel: {
    '& label': {
      color: theme.palette.neutral.darker,
      fontSize: `${theme.typography.pxToRem(16)}!important`,
      fontWeight: '600!important',
    },
  },
  btnMarginLeft: {
    marginLeft: 10,
  },
}));

export const BindPopover = ({
  idBind,
  openBind,
  anchorElBind,
  handleCloseBindPopover,
  handleBindQuoteAndClosePopover,
  riskInceptionDate,
  riskExpiryDate,
  earliestBindPeriod,
  enablePreBindConfirmation,
  preBindConfirmationText,
}) => {
  const classes = useStyles();
  const [confirmationChecked, setConfirmationChecked] = useState(false);
  const [selectedInceptionDate, setInceptionDate] = useState(riskInceptionDate);
  const [selectedExpiryDate, setExpiryDate] = useState(riskExpiryDate);
  const [isValidDate, setIsValidDate] = useState(true);
  const daysDiff = utils.date.diffDays(riskExpiryDate, riskInceptionDate);
  const today = utils.date.today();

  const handleInceptionDateChange = (date) => {
    setInceptionDate(date);
    setExpiryDate(utils.date.addDays(date, daysDiff));
  };

  useEffect(() => {
    const isBindable =
      utils.date.diffDays(selectedInceptionDate, today) <= earliestBindPeriod &&
      utils.date.isSameOrAfter(selectedExpiryDate, utils.date.addDays(today, earliestBindPeriod));
    if (utils.date.isValid(selectedInceptionDate) && utils.date.isValid(selectedExpiryDate) && isBindable) {
      setIsValidDate(true);
    } else {
      setIsValidDate(false);
    }
  }, [selectedInceptionDate, selectedExpiryDate, earliestBindPeriod, today]);

  const disabled = !isValidDate || (enablePreBindConfirmation && !confirmationChecked);

  return (
    <Popover
      id={idBind}
      open={openBind}
      anchorEl={anchorElBind}
      onClose={handleCloseBindPopover}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
    >
      <Box className={classes.bindBox} data-testid="bind-popover">
        <FormGrid container spacing={1} justifyContent="center" alignItems="top">
          <FormGrid
            item
            xs={6}
            classes={{
              root: classes.dateLabel,
            }}
          >
            <KeyboardDatePicker
              autoOk
              value={selectedInceptionDate}
              placeholder="21/10/2022"
              onChange={(date) => handleInceptionDateChange(date)}
              maxDate={utils.date.addDays(today, earliestBindPeriod)}
              format="DD-MM-yyyy"
              label="Inception Date"
              maxDateMessage={utils.string.t('products.notBindable', { days: earliestBindPeriod })}
            />
          </FormGrid>
          <FormGrid
            item
            xs={6}
            classes={{
              root: classes.dateLabel,
            }}
          >
            <KeyboardDatePicker
              autoOk
              value={selectedExpiryDate}
              placeholder="21/10/2023"
              minDate={utils.date.addDays(today, earliestBindPeriod)}
              format="DD-MM-yyyy"
              disablePast
              label="Expiry Date"
              disabled
            />
          </FormGrid>
          {enablePreBindConfirmation ? (
            <FormGrid item xs={12}>
              <Box display="flex" width="100%" flexDirection="row" justifyContent="flex-start" alignContent="center">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={confirmationChecked}
                      onChange={(e) => setConfirmationChecked(e.target.checked)}
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label={preBindConfirmationText}
                />
              </Box>
            </FormGrid>
          ) : null}
          <FormGrid item xs={12}>
            <Box display="flex" width="100%" flexDirection="row" justifyContent="flex-end">
              <Button
                data-testid="bind-ok-button"
                size="xsmall"
                icon={DoneIcon}
                color="default"
                style={{ backgroundColor: !disabled ? 'rgb(44, 198, 171)' : '', color: !disabled ? 'white' : '' }}
                nestedClasses={{ icon: classes.icon }}
                onClick={() =>
                  handleBindQuoteAndClosePopover(utils.date.toISOString(selectedInceptionDate), utils.date.toISOString(selectedExpiryDate))
                }
                disabled={disabled}
              />
              <Button
                data-testid="bind-cancel-button"
                size="xsmall"
                icon={ClearIcon}
                color="default"
                nestedClasses={{ btn: classes.btnMarginLeft, icon: classes.icon }}
                onClick={handleCloseBindPopover}
              />
            </Box>
          </FormGrid>
        </FormGrid>
      </Box>
    </Popover>
  );
};
