import { useEffect, useState } from 'react';
import * as Yup from 'yup';

import { useCreateOperationalClient, useEditOperationalClient, useGetODSParentClientList } from 'lib/admin';
import * as utils from 'utils';

// app
import { AddEditParentClientView } from './AddEditParentClient.view';

export default function AddEditParentClient({ handleClose, client = {} }) {
  const [page, setPage] = useState(0);
  const [searchStr, setSearchStr] = useState('');
  const [allOptions, setAllOptions] = useState([]);
  const { mutate: createOperationalClient } = useCreateOperationalClient();
  const { mutate: editOperationalClient } = useEditOperationalClient();

  const queryKey = ['odsParentClients', searchStr, page];
  const { data: gxbParentList, isFetching } = useGetODSParentClientList(
    {
      size: 10,
      page,
      searchStr,
    },
    {
      queryKey,
      keepPreviousData: true,
      staleTime: 5 * 60 * 1000,
      cacheTime: 10 * 60 * 1000,
    }
  );

  useEffect(() => {
    if (gxbParentList) {
      setAllOptions((prev) => (page === 1 ? gxbParentList.content : [...new Set([...prev, ...gxbParentList.content])]));
    }
  }, [gxbParentList, page]);

  const handleLoadMore = () => {
    if (!isFetching) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const handleSearch = (newSearchValue) => {
    setSearchStr(newSearchValue);
    setPage(0);
    if (newSearchValue !== '') {
      setAllOptions([]);
    }
  };

  const handleSubmit = (parentClient) => {
    if (client.id) {
      editOperationalClient({ newParentClient: parentClient, id: client.id });
    } else {
      createOperationalClient(parentClient);
    }
  };

  const handleCancel = () => {
    if (utils.generic.isFunction(handleClose)) {
      handleClose();
    }
  };

  const fields = [
    {
      name: 'name',
      label: utils.string.t('admin.form.client.label'),
      type: 'text',
      value: client?.name ? client.name : '',
      innerComponentProps: {
        maxMenuHeight: 120,
        allowEmpty: true,
        'data-testid': 'parent',
      },
      validation: Yup.string().required(utils.string.t('validation.required')),
    },
    {
      name: 'odsParentClientName',
      label: utils.string.t('admin.form.odsParentClientName.label'),
      type: 'autocomplete',
      options: gxbParentList?.data?.content || [],
      value: client?.odsParentClientName ? client.odsParentClientName : '',
    },
    {
      type: 'file',
      name: 'logo',
      label: utils.string.t('app.file'),
      placeholder: 'Select File ...',
      value: client?.logo ? client.logo : '',
      validation: client.id ? Yup.mixed().nullable() : Yup.mixed().nullable().required(utils.string.t('form.dragDrop.required')),
    },
  ];

  const logo = utils.client.parent.getLogoFilePath(client);

  const actions = [
    {
      name: 'submit',
      label: client.id ? utils.string.t('app.save') : utils.string.t('app.create'),
      handler: handleSubmit,
    },
    {
      name: 'cancel',
      label: utils.string.t('app.cancel'),
      handler: handleCancel,
    },
  ];

  return (
    <AddEditParentClientView
      actions={actions}
      fields={fields}
      logo={logo}
      allOptions={allOptions}
      handleLoadMore={handleLoadMore}
      loading={isFetching}
      onSearch={handleSearch}
    />
  );
}
