import { useRef, useState } from 'react';

import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import * as utils from 'utils';

export const ParentSelect = ({ allOptions = [], value, onChange, onLoadMore, onSearch, loading = false }) => {
  const listRef = useRef();
  const [lastSearchValue, setLastSearchValue] = useState('');

  const handleScroll = (event) => {
    const list = event.target;

    if (list.scrollHeight - list.scrollTop - list.clientHeight < 50) {
      onLoadMore();
    }
  };

  const handleOpen = () => {
    if (!allOptions.length && lastSearchValue) {
      onSearch(lastSearchValue);
    }
  };

  return (
    <Autocomplete
      value={value}
      onChange={(event, newValue) => {
        onChange(newValue);
      }}
      onInputChange={(event, newInputValue, reason) => {
        if (reason === 'input') {
          setLastSearchValue(newInputValue);
          onSearch(newInputValue);
        }
        if (reason === 'clear') {
          setLastSearchValue('');
          onSearch('');
          onChange(null);
        }
      }}
      options={allOptions}
      loading={loading}
      ListboxProps={{
        onScroll: handleScroll,
        ref: listRef,
      }}
      onOpen={handleOpen}
      renderInput={(params) => (
        <TextField
          {...params}
          label={utils.string.t('admin.form.odsParentClientName.label')}
          placeholder="Search.."
          variant="outlined"
          fullWidth
        />
      )}
      getOptionLabel={(option) => option || ''}
      filterOptions={(options) => options}
      clearOnBlur={false}
      openOnFocus
    />
  );
};
