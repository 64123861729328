import { useQuery } from 'react-query';

import * as utils from 'utils';
import { logoutAuth0 } from 'utils/auth0/auth0';

import { adminAxios } from '../../admin.api';
import { ODS_PARENT_CLIENT_LIST } from '../../queryKeys';

export const useGetODSParentClientList = (params) => {
  const handleError = (err) => {
    const errorParams = {
      file: 'admin.queries.getGXBParentList',
    };

    utils.api.handleError(err, errorParams);
    utils.api.handleUnauthorized(err, null, logoutAuth0);
  };

  return useQuery(
    [ODS_PARENT_CLIENT_LIST, params],
    async () => {
      const { size, page, searchStr } = params;
      const result = await adminAxios.get(`client/gxb/parent?size=${size}&page=${page}&searchStr=${searchStr}`);

      return result?.data;
    },
    {
      onError: (err) => handleError(err),
      keepPreviousData: true,
    }
  );
};
