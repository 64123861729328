import Cookies from 'js-cookie';

import * as utils from 'utils';

const LAST_ACTIVITY_KEY = 'lastActivity';
export const ACTIVE_TIMEOUT_THRESHOLD = 15 * 60 * 1000; // 15 minutes in milliseconds
export const BACKGROUND_TIMEOUT_THRESHOLD = 45 * 60 * 1000; // 45 minutes in milliseconds

const cookieOptions = {
  domain: utils.app.isEdgeProduction() ? utils.app.PRODUCTION_COOKIE_DOMAIN : utils.app.NON_PRODUCTION_COOKIE_DOMAIN,
  secure: false,
  sameSite: 'Strict',
};

export const updateLastActivity = (): void => {
  Cookies.set(LAST_ACTIVITY_KEY, Date.now().toString(), cookieOptions);
};

export const removeLastActivity = (): void => {
  Cookies.remove(LAST_ACTIVITY_KEY, cookieOptions);
};

export const getLastActivity = (): number | null => {
  const lastActivity = Cookies.get(LAST_ACTIVITY_KEY);
  return lastActivity ? parseInt(lastActivity, 10) : null;
};

export const checkBackgroundInactivity = (): boolean => {
  const lastActivity = getLastActivity();
  if (!lastActivity) return false;

  const currentTime = Date.now();
  return currentTime - lastActivity > BACKGROUND_TIMEOUT_THRESHOLD;
};

export const checkActiveTabInactivity = (): boolean => {
  const lastActivity = getLastActivity();
  if (!lastActivity) return false;

  const currentTime = Date.now();
  return currentTime - lastActivity > ACTIVE_TIMEOUT_THRESHOLD;
};

export const getRemainingTime = (): number => {
  const lastActivity = getLastActivity();
  if (!lastActivity) return 0;

  const currentTime = Date.now();
  return currentTime - lastActivity;
};
