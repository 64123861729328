import { useAuth0 } from '@auth0/auth0-react';
import { useSelector } from 'react-redux';

import { App, Loader } from 'components';
import Login from 'pages/Login/Login';
import EarlyLogout from './EarlyLogout';
import { selectUser } from 'stores';

import { sec } from 'utils/auth0/auth0';
import * as utils from 'utils';

const EdgeApp = () => {
  const { isAuthenticated, isLoading, getAccessTokenSilently, logout } = useAuth0();
  sec.setAccessTokenSilently(getAccessTokenSilently);
  sec.setLogout(() => logout({ logoutParams: { returnTo: window.location.origin } }));
  const user = useSelector(selectUser);

  if (isLoading) {
    return <Loader absolute label={utils.string.t('app.authenticating')} />;
  }

  if (!isAuthenticated) {
    return <Login />;
  }
  if (user.earlyLogout) {
    return (
      <EarlyLogout>
        <App />
      </EarlyLogout>
    );
  }

  return <App />;
};

export default EdgeApp;
