import React, { useCallback, useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useAuth0 } from '@auth0/auth0-react';

import {
  ACTIVE_TIMEOUT_THRESHOLD,
  BACKGROUND_TIMEOUT_THRESHOLD,
  checkBackgroundInactivity,
  getLastActivity,
  removeLastActivity,
  updateLastActivity,
} from './utils/activityTracker';

interface EarlyLogoutProps {
  children: React.ReactNode;
}

const EarlyLogout: React.FC<EarlyLogoutProps> = ({ children }) => {
  const { isAuthenticated, logout } = useAuth0();
  const [isLoggingOut, setIsLoggingOut] = useState(false);

  const handleLogout = useCallback(() => {
    setIsLoggingOut(true);
    logout({ logoutParams: { returnTo: window.location.origin } });
    removeLastActivity();
  }, [logout]);

  const handleOnIdle = useCallback(() => {
    handleLogout();
  }, [handleLogout]);

  const handleOnActive = useCallback(() => {
    updateLastActivity();
  }, [updateLastActivity]);

  const handleOnAction = useCallback(() => {
    updateLastActivity();
  }, [updateLastActivity]);

  const { start, pause } = useIdleTimer({
    timeout: ACTIVE_TIMEOUT_THRESHOLD,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500,
    crossTab: true,
    name: 'idle-timer',
    leaderElection: true,
    syncTimers: 200,
  });

  useEffect(() => {
    if (checkBackgroundInactivity()) {
      handleLogout();
    }

    const checkBackgroundActivity = setInterval(() => {
      const lastActivity = getLastActivity();
      if (lastActivity) {
        const inactiveTime = Date.now() - lastActivity;
        if (inactiveTime > BACKGROUND_TIMEOUT_THRESHOLD) {
          handleLogout();
        }
      }
    }, 10 * 1000); // Check every 10 seconds

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        start();
      } else {
        pause();
      }

      if (isAuthenticated && !isLoggingOut) {
        updateLastActivity();
      }
    };

    window.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      window.removeEventListener('visibilitychange', handleVisibilityChange);

      if (checkBackgroundActivity) {
        clearInterval(checkBackgroundActivity);
      }
    };
  }, [handleLogout, isAuthenticated, isLoggingOut]);

  if (isLoggingOut) {
    return null;
  }

  return <>{children}</>;
};

export default EarlyLogout;
